'use client'

import {
  CloseButton,
  Combobox,
  Input,
  InputBase,
  useCombobox,
} from '@mantine/core'
import { useState } from 'react'

type Props<T> = {
  data: T[]
  name: string
  label: string
  error: string[] | undefined
  getOptionLabel: (option: T) => string
  getOptionValue: (option: T) => string
}

const SearchableSelect = <T,>({
  data,
  name,
  label,
  error,
  getOptionLabel,
  getOptionValue,
}: Props<T>) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const [value, setValue] = useState<string | null>(null)
  const [search, setSearch] = useState('')

  const filteredOptions = search
    ? data.filter((data) =>
        getOptionLabel(data).toLowerCase().includes(search.toLowerCase().trim())
      )
    : data

  const options = filteredOptions.map((item) => (
    <Combobox.Option
      value={getOptionValue(item)}
      key={getOptionValue(item)}
      className="hover:!bg-battery-triangle data-[combobox-selected=true]:!bg-battery-triangle"
    >
      {getOptionLabel(item)}
    </Combobox.Option>
  ))

  return (
    <>
      <input hidden name={name} value={value || ''} readOnly />

      <Combobox
        store={combobox}
        withinPortal={true}
        onOptionSubmit={(val) => {
          const selectedOption = data.find(
            (data) => getOptionValue(data) === val
          )
          setValue(val)
          if (selectedOption) {
            setSearch(getOptionLabel(selectedOption))
          }
          combobox.closeDropdown()
        }}
        styles={{
          dropdown: {
            backgroundColor: 'rgb(0, 42, 58)',
            color: 'white',
            zIndex: '99999',
          },
        }}
      >
        <Input.Wrapper label={label} error={error}>
          <Combobox.Target>
            <InputBase
              rightSection={
                value !== null ? (
                  <CloseButton
                    size="sm"
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => {
                      setValue(null)
                      setSearch('')
                      combobox.focusTarget()
                      combobox.openDropdown()
                    }}
                    aria-label="Clear value"
                  />
                ) : (
                  <Combobox.Chevron />
                )
              }
              rightSectionPointerEvents={value === null ? 'none' : 'all'}
              placeholder="Search"
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => {
                combobox.closeDropdown()
                const selectedOption = data.find(
                  (data) => getOptionValue(data) === value
                )
                if (!selectedOption) {
                  setSearch(value || '')
                }
              }}
              value={search}
              onChange={(event) => {
                combobox.openDropdown()
                combobox.updateSelectedOptionIndex()
                setSearch(event.currentTarget.value)
              }}
            />
          </Combobox.Target>
        </Input.Wrapper>

        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </>
  )
}

export default SearchableSelect
