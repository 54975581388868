import { AppContext } from '@/components/AppProvider'
import { CompanyProject, Project } from '@/types'
import { useCallback, useContext, useMemo } from 'react'

const useProjects = <T extends Project[] | CompanyProject[]>(
  projects: T,
  showPersonalProjects: boolean
): {
  projects: T
  userHasAssociatedProjects: boolean
  isProjectMember: (projectId: string) => boolean
} => {
  const { user } = useContext(AppContext)

  const isProjectMember = useCallback(
    (projectId: string) => {
      const project = projects.find((project) => project.id === projectId)

      if (!project || !user || !user?.salesforceId) {
        return false
      }

      return (
        `${project.teamMemberSalesforceIds}${project.ownerSalesforceId}`.indexOf(
          user.salesforceId!
        ) > -1
      )
    },
    [projects, user]
  )

  const userProjects = useMemo(() => {
    if (!user || !user?.salesforceId) {
      return [] as unknown as T
    }

    return projects.filter((project) => isProjectMember(project.id)) as T
  }, [isProjectMember, projects, user])

  return {
    projects:
      userProjects.length > 0 && showPersonalProjects ? userProjects : projects,
    userHasAssociatedProjects: userProjects.length > 0,
    isProjectMember,
  }
}

export default useProjects
