'use client'

import useProjects from '@/hooks/useProjects'
import { Project } from '@/types'
import { Button, Loader } from '@mantine/core'
import { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { useEffect, useState } from 'react'
import { useFormState, useFormStatus } from 'react-dom'
import { addCompanyToProject } from './extension/pop-up/popupProjectActions'
import SearchableSelect from './SearchableSelect'

const SubmitButton = () => {
  const { pending } = useFormStatus()

  return (
    <Button
      color="rgb(0, 42, 58)"
      type="submit"
      w="100%"
      disabled={pending}
      className="flex gap-2 justify-center mt-4"
      rightSection={
        pending && <Loader color="rgb(0, 42, 58)" type="dots" size={14} />
      }
    >
      Submit
    </Button>
  )
}

export default function AddToProjectModal({
  context,
  id,
  innerProps: { companyOrgId, companySalesforceId },
}: ContextModalProps<{ companyOrgId: string; companySalesforceId: string }>) {
  const [shownToast, setShownToast] = useState(false)
  const [projectOptions, setProjectOptions] = useState<Project[]>([])
  const [loading, setLoading] = useState(false)
  const { projects } = useProjects(projectOptions, true)
  const [{ errors, done, success, title, message }, formAction] = useFormState(
    addCompanyToProject,
    {
      errors: {},
      done: false,
      success: false,
      title: '',
      message: '',
    }
  )

  useEffect(() => {
    setLoading(true)
    fetch('/api/projects')
      .then((res) => res.json())
      .then((res: { value: Project[] }) => setProjectOptions(res.value))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (done) {
      if (success) {
        context.closeModal(id)
      }

      if (!shownToast) {
        notifications.show({
          title: title,
          message: message,
          color: success ? 'green' : 'red',
        })

        setShownToast(true)
      }
    }
  }, [context, done, id, message, shownToast, success, title])

  if (loading) {
    return (
      <div className="text-sm flex flex-row gap-2 justify-center items-center">
        <Loader color="rgb(0, 42, 58)" type="bars" size={14} />
        Finding Projects
      </div>
    )
  }

  return (
    <form action={formAction} noValidate>
      <input hidden readOnly name="companyOrgId" value={companyOrgId} />
      <input
        hidden
        readOnly
        name="companySalesforceId"
        value={companySalesforceId}
      />

      <SearchableSelect
        label="Project"
        name="projectId"
        error={errors.projectId}
        data={projects}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
      />
      <SubmitButton />
    </form>
  )
}
